$padding: 15px;

:root{
    --slider-height: 230px;
    --slide-gap: 25px;
}

.tns-outer {
  button {
    display: none !important;
  }
}

.tns-item{
  width: auto !important;
}


.slider {
    width: 100%;
    text-align: center;
    perspective: 100px;
    position: relative;

    &__snapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      scroll-snap-align: center;
    }
}

#slider__controls{
  width: 100%;
}

.slider__viewport {
    display: flex;
    overflow-x: auto;
    counter-reset: item;
    scroll-snap-type: x mandatory;
    scroll-padding-inline-start: $padding;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.746);
    border-radius: 10px;
    
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}
  .slide {
    //scroll-snap-align: start;
    flex-shrink: 0;
    width: auto;
    height: var(--slider-height);
    //margin-right: var(--slide-gap);
    //border-radius: 10px;
    //background: #eee;
    //transform-origin: center center;
    //transform: scale(1);
    //transition: transform 0.5s;
    position: relative;
    overflow: hidden;
    
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 100px;

    //counter-increment: item;

    // &:first-child {
    //   margin-left: $padding;
    // }
    
    // &:last-child {
    //   margin-right: $padding;
    // }

    img {
      border-radius: 10px;
        width: 100%;
        height: 100%;
      }
  }


.slider__prev,
.slider__next {
  content: '';
  z-index: 1;
  background-color: #333;
  background-size: 1.5rem 1.5rem;
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff;
  font-size: 2.5rem;
  line-height: 4rem;
  text-align: center;
  position: absolute;
  top: 50%;
  width: 4rem;
  height: 4rem;
  transform: translateY(-50%);
  border-radius: 50%;
  font-size: 0;
  outline: 0;
}

.slider__prev {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='0,50 80,100 80,0' fill='%23fff'/%3E%3C/svg%3E");

  left: 0;
  transform: translate(-50%, -50%);
}

.slider__next {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='100,50 20,100 20,0' fill='%23fff'/%3E%3C/svg%3E");

  right: 0;
  transform: translate(50%, -50%);
}
