.showcase {
    .showcase-text {
        padding: 3rem;
    }

    .showcase-img {
        min-height: 35rem;
        background-size: cover;
        background-position: center;    
    }

    @media (min-width: 768px) {
        .showcase-text {
            padding: 7rem;
        }
    }
}