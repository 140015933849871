.facts{
    &-container{
        display: flex;
        flex-direction: column;
        width: min-content;
        margin: 0 auto;
    
        @media (min-width: 768px) {
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }
}

.fact{
    align-items: center;

    &-details{
        flex-direction: column;
        align-items: left;
    }

    &, &-details{
        display: flex;
    }

    &-icon{
        height: 65px;
        width: auto;
    }
}