.testimonial{
  display: flex;
  flex-direction: column;
  align-items: center;

  &-image{
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  &-details{
    display: flex;
  }

  &-details{
    

    .name{
      font-size: smaller;
    }

    // .text{
    //   font-size: smaller; 
    //   font-style: italic;
    // }
  }

  .blockquote {
      position: relative;
      font-family: 'Barlow Condensed', sans-serif;
      max-width: 620px;
      margin: 20px auto;
      width: 100%;
  }

  /* Blockquote header */
  .blockquote h1 {
      //font-family: 'Abril Fatface', cursive;
      font-style: italic;
      position: relative; /* for pseudos */
      color: #F08B47;
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.2;
      margin: 0;
      border: 2px solid #fff;
      border: solid 2px;
      border-radius:20px;
      padding: 25px;
  }

  /* Blockquote right double quotes */
  .blockquote h1:after {
      content:"";
      position: absolute;
      border: 2px solid #F08B47;
      border-radius: 0 50px 0 0;
      width: 60px;
      height: 60px;
      bottom: -60px;
      left: 25px;
      border-bottom: none;
      border-left: none;
      z-index: 3; 
  }

  .blockquote h1:before {
      content:"";
      position: absolute;
      width: 80px;
      border: 6px solid white;
      bottom: -3px;
      left: 25px;
      z-index: 2;
  }

  /* increase header size after 600px */
  // @media all and (min-width: 600px) {
  //     .blockquote h1 {
  //         font-size: 3rem;
  //         line-height: 1.2;
  //   }

  // }

  /* Blockquote subheader */
  .blockquote h4 {
      position: relative;
      ///color: #ffffff;
      font-size:smaller;
      font-weight: 400;
      line-height: 1.2;
      margin: 0;
      padding-top: 15px;
      z-index: 1;
      margin-left:100px;
      padding-left:12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
  }

  
  .blockquote h4:first-letter {
    margin-left:-12px;
  }
}