.share__wrapper {
  display: flex;
  margin: 2rem 0;
}

.share__title {
  align-self: flex-end;
  margin-bottom: 0;
  font-size: 1.25rem;
}

.share__list {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0;
  list-style: none;
  align-items: center;
  padding: 0;
}

.share__item {
  
  & + & {
    margin-top: 1rem;
  }
}

.share__link {
  border: none;  
  display: grid;
  grid-template-columns: 45px 1fr;
  column-gap: 10px;


  svg{
    
    justify-self: center;
  }
  
  
  > * {
    pointer-events: none;
  }
}