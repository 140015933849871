.shop{
  $breakpoint-md: 768px;
  &__info{

    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-md) {
      flex-direction: row;
    }

    &__text{
      @media (min-width: $breakpoint-md) {
        width: 50%;
      }
    }

    &__map{
      @media (min-width: $breakpoint-md) {
        width: 50%;
      }
    }
  }
}