.map-info{
  padding-left: 15px;
  font-size: 0.85rem;

  &:before{
    content: '!';
    font-weight: 900;
    font-size: 1.8rem;
    color: #F08B47;
    position: absolute;
    left: 8px;
    top: 4px;
  }
}
