.section{
    font-size: 1.2rem;
    
    &--wite{
        background-color: #fff;
    }

    &--orange{
        background-color: #F08B47;
        color: white;
    }

    &--blue{
        background-color: #1B1464;
        color: white;
    }

    &--black{
        background-color: #000;
        color: rgb(233, 233, 233);
    }

    &--gray{
        background-color: #464f59;
        color: rgb(233, 233, 233);
    }


    &--orange, &--blue, &--black, &--gray{
        a{
            color: rgb(174, 174, 252);
        }
    }
    

    p, li{
        b{
            background-color: yellow;
            color: black;
            border-radius: 5px;
            padding-inline: 4px;
        }
    }
}