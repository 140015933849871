.call-to-action {
    position: relative;
    background-color: $gray-800;
    //background-image: url('../assets/img/bg-feet-sm-1x.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 7rem;
    padding-bottom: 7rem;

    .overlay {
        position: absolute;
        background-color: $gray-900;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        opacity: 0.3;
    }

    h2{
        text-shadow: 0 0 5px rgba(0,0,0, .4);
    }

    @media (min-width: 768px) {
        padding-top: 12rem;
        padding-bottom: 12rem;
        //background-image: url('../assets/img/bg-feet-stab-1x.jpg');

        h1 {
            font-size: 3rem;
        }
    }

    @media (min-width: 1024px) {
        //background-image: url('../assets/img/bg-feet-tab-1x.jpg');
    }

    @media (min-width: 1280px) {
        //background-image: url('../assets/img/bg-feet-dt-1x.jpg');


        @media
            only screen and (-webkit-min-device-pixel-ratio: 2),
            only screen and (   min--moz-device-pixel-ratio: 2),
            only screen and (     -o-min-device-pixel-ratio: 2/1),
            only screen and (        min-device-pixel-ratio: 2),
            only screen and (                min-resolution: 192dpi),
            only screen and (                min-resolution: 2dppx) { 
              //background-image: url('../assets/img/bg-feet-dt-2x.jpg');

            }
    }
}
