:root{
    --masthead-sm-bg-lp1-old: url('Images/responsive/landing-hero-mobile-2.png?size=390&format=webp');
    --masthead-sm-bg: url('Images/responsive/landing-hero-mobile-2.png?size=390&format=webp');
    --masthead-sm-bg-2x: url('Images/responsive/landing-hero-mobile-2.png?size=780&format=webp');
    --masthead-md-bg: url('Images/responsive/landing-hero-tablet-2.png?size=540&format=webp');
    --masthead-md-bg-2x: url('Images/responsive/landing-hero-tablet-2.png?size=1080&format=webp');
    --masthead-lg-bg: url('Images/responsive/landing-hero-tablet-2.png?size=768&format=webp');
    --masthead-lg-bg-2x: url('Images/responsive/landing-hero-tablet-2.png?size=1536&format=webp');
    --masthead-xl-bg: url('Images/responsive/landing-hero-tablet-2.png?size=1024&format=webp');
    --masthead-xl-bg-2x: url('Images/responsive/landing-hero-tablet-2.png?size=2048&format=webp');
    --masthead-dt-bg: url('Images/responsive/landing-hero-desktop-2.png?size=1920&format=webp');
    --masthead-dt-bg-2x: url('Images/responsive/landing-hero-desktop-2.png?size=3840&format=webp');
}   

header.masthead {
    &.lp2{
        --masthead-sm-bg: url('Images/responsive/landing-hero-mobile-lp2.png?size=390&format=webp');
        --masthead-sm-bg-2x: url('Images/responsive/landing-hero-mobile-lp2.png?size=780&format=webp');
        --masthead-md-bg: url('Images/responsive/landing-hero-tablet-lp2.png?size=540&format=webp');
        --masthead-md-bg-2x: url('Images/responsive/landing-hero-tablet-lp2.png?size=1080&format=webp');
        --masthead-lg-bg: url('Images/responsive/landing-hero-tablet-lp2.png?size=768&format=webp');
        --masthead-lg-bg-2x: url('Images/responsive/landing-hero-tablet-lp2.png?size=1536&format=webp');
        --masthead-xl-bg: url('Images/responsive/landing-hero-tablet-lp2.png?size=1024&format=webp');
        --masthead-xl-bg-2x: url('Images/responsive/landing-hero-tablet-lp2.png?size=2048&format=webp');
        --masthead-dt-bg: url('Images/responsive/landing-hero-desktop-lp2.png?size=1920&format=webp');
        --masthead-dt-bg-2x: url('Images/responsive/landing-hero-desktop-lp2.png?size=3840&format=webp');
    }


    position: relative;
    background-color: white;
    //background-image: url('Images/responsive/landing-hero-mobile@3x.png?size=390&format=webp');
    background-image: var(--masthead-sm-bg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 8rem;
    padding-bottom: 8rem;
    min-height: calc(100vw * 1.43);
    height: 143vw;

    picture {
        display: block;
        max-width: 100%;
        height: auto;
    }

    @media
            only screen and (-webkit-min-device-pixel-ratio: 2),
            only screen and (   min--moz-device-pixel-ratio: 2),
            only screen and (     -o-min-device-pixel-ratio: 2/1),
            only screen and (        min-device-pixel-ratio: 2),
            only screen and (                min-resolution: 192dpi),
            only screen and (                min-resolution: 2dppx) { 
              //background-image: url('Images/responsive/landing-hero-mobile@3x.png?size=780&format=webp');
              background-image: var(--masthead-sm-bg-2x);
            }

    .overlay {
        position: absolute;
        background-color: $gray-900;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        opacity: 0.3;
    }

    h1 {
        font-size: 2rem;
        text-shadow: 0 0 5px rgba(0,0,0,.4);
    }

    @media (min-width: 540px) {
        padding-top: 12rem;
        padding-bottom: 12rem;
        //background-image:  url('Images/responsive/landing-hero-tablet@3x.png?size=540&format=webp');
        background-image:  var(--masthead-md-bg); 
        min-height: calc(540px * .7);
        height: 70vw;

        @media
            only screen and (-webkit-min-device-pixel-ratio: 2),
            only screen and (   min--moz-device-pixel-ratio: 2),
            only screen and (     -o-min-device-pixel-ratio: 2/1),
            only screen and (        min-device-pixel-ratio: 2),
            only screen and (                min-resolution: 192dpi),
            only screen and (                min-resolution: 2dppx) { 
              //background-image: url('Images/responsive/landing-hero-tablet@3x.png?size=1080&format=webp');
              background-image: var(--masthead-md-bg-2x);
            }

        h1 {
            font-size: 3rem;
        }
    }

    @media (min-width: 768px) {
        padding-top: 12rem;
        padding-bottom: 12rem;
        //background-image:  url('Images/responsive/landing-hero-tablet@3x.png?size=768&format=webp');
        background-image:  var(--masthead-lg-bg);

        @media
            only screen and (-webkit-min-device-pixel-ratio: 2),
            only screen and (   min--moz-device-pixel-ratio: 2),
            only screen and (     -o-min-device-pixel-ratio: 2/1),
            only screen and (        min-device-pixel-ratio: 2),
            only screen and (                min-resolution: 192dpi),
            only screen and (                min-resolution: 2dppx) { 
              //background-image: url('Images/responsive/landing-hero-tablet@3x.png?size=1536&format=webp');
              background-image: var(--masthead-lg-bg-2x);
            }

        min-height: 533px;

        h1 {
            font-size: 3rem;
        }
    }

    @media (min-width: 1024px) {
        /// V1
        //background-image: url('Images/responsive/landing-hero-tablet@3x.png?size=1024&format=webp');
        max-height: calc( 100vh - 160px);
        
        /// v2
        background-image: var(--masthead-xl-bg);
        background-position: top;
        background-size: contain;

        
        @media
            only screen and (-webkit-min-device-pixel-ratio: 2),
            only screen and (   min--moz-device-pixel-ratio: 2),
            only screen and (     -o-min-device-pixel-ratio: 2/1),
            only screen and (        min-device-pixel-ratio: 2),
            only screen and (                min-resolution: 192dpi),
            only screen and (                min-resolution: 2dppx) { 
             //background-image: url('Images/responsive/landing-hero-tablet@3x.png?size=2048&format=webp');
              background-image: var(--masthead-xl-bg-2x);
            }

            
    }

    @media (min-width: 1280px) {
        //background-image:  url('Images/responsive/landing-hero-desktop@3x.png?size=1920&format=webp');
        background-image:  var( --masthead-dt-bg);
        min-height: calc(540px * .5);
        height: 50vw;

        @media
            only screen and (-webkit-min-device-pixel-ratio: 2),
            only screen and (   min--moz-device-pixel-ratio: 2),
            only screen and (     -o-min-device-pixel-ratio: 2/1),
            only screen and (        min-device-pixel-ratio: 2),
            only screen and (                min-resolution: 192dpi),
            only screen and (                min-resolution: 2dppx) { 
              //background-image: url('Images/responsive/landing-hero-desktop@3x.png?size=1920&format=webp');
              background-image: var( --masthead-dt-bg-2x);
            }
    }
}