
:root{
    --primary-button-bg-color: #F08B47;
    --primary-button-bg-color-hover: #f0975b;
    --primary-button-color: #fff;
    --inverted-button-bg-color: #fff;
    --inverted-button-bg-color-hover: #222;
    --inverted-button-color: #222;
    --inverted-button-color-hover: #fff;
    --button-corner-radius: 24px;
}

@mixin cta-button {
    display: flex !important;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    height: 48px;
    border: 0 !important;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    box-shadow: 0 3px 0 0 rgba(0,0,0,.1) !important;
    border-radius: var(--button-corner-radius) !important;
    padding-inline-start: 30px;
    padding-inline-end: 30px;
    background-image: none !important;
}

@mixin primary-button {
    @include cta-button;
    color: var(--primary-button-color) !important;
    background-color: var(--primary-button-bg-color) !important;

    &:hover{
        background-color: var(--primary-button-bg-color-hover) !important;
    }
}

.btn-lg{
    .spinner-grow{
        width: 1.25em;
        height: 1.25em;
    }
}

.booked4-us-button{
    @include primary-button;

    @at-root .btn-white & {
        background-color: var(--inverted-button-bg-color) !important;
        color: var(--inverted-button-color) !important;

        &:hover{
            background-color: var(--inverted-button-bg-color-hover) !important;
            color: var(--inverted-button-color-hover) !important;
        }
    }
}